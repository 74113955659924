<template>
  <module-container
    :title="$t('debt.collection')"
    dense
    hide-new-button
    route-name="invoices.debt"
    show-toolbar
  >
    <template #route>
      <invoices-list
        :hide-headers="['is_cash']"
        :invoice-type="[701, 901]"
        base-route="invoices.debt"
        filter-key="debt_emitted"
        signed
      />
    </template>

    <template #filters>
      <invoice-filters filter-key="debt_emitted" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesList from "@/modules/invoices/components/InvoicesList.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import { findIndex } from "lodash";
import { EventBus } from "@/services/event-bus";

@Component({
  components: { InvoiceFilters, InvoicesList },
})
export default class InvoicesDebtEmitted extends Mixins(PaginateProviderMixin) {
  onSetHeaders(arHeaders: DataTableHeader[]) {
    const idx = findIndex(arHeaders, { value: "invoice_type" });
    if (idx >= 0) {
      arHeaders.splice(idx + 1, 0, {
        value: "flag",
        text: "invoice.status",
        sortable: false,
      });
    }
  }

  created() {
    EventBus.on("invoices.list.apply.headers", this.onSetHeaders);
  }

  beforeDestroy() {
    EventBus.off("invoices.list.apply.headers");
  }
}
</script>
